import * as React from "react"
import styled from "styled-components"
import Overline from "../../shared/overline"
import Title from "../../shared/title"
import Text from "../../shared/text"
import GetStartedBtn from "../../shared/get-started-btn"
import { vw } from "../../../utilities/pxtovw"
import MtnMomoLogo from "../../../images/partners/mtn-momo-logo.jpg"
import AirtelMoneyLogo from "../../../images/partners/airtel-money-logo.jpg"
import StanbicLogo from "../../../images/logos/sb_small.jpg"
import { Link } from "gatsby"

const Box = styled.div`
  height: 100%;
  flex: 1;

  > section {
    padding-top: ${vw(72)};
    padding-left: 5rem;
    width: ${vw(433)};
  }

  @media (max-width: 766px) {
    height: auto;
    padding: 7.5rem 2rem 4rem;
    > section {
      width: auto;
      padding-left: 0;
      padding-top: 0;
    }
  }
`

const Partners = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  gap: 1.25rem;
  margin-top: 5.75rem;
  position: relative;

  .tag {
    position: absolute;
    top: -1.5rem;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 0.5625rem;
    opacity: 50%;
  }

  .partnerLogo {
    width: 2.8rem;
    height: 2.8rem;
    overflow: hidden;
    border-radius: 9px;
    display: block;

    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
`
const Actions = styled.div`
  padding-top: ${vw(36)};

  @media (max-width: 766px) {
    padding-top: 2.5rem;
  }
`
const Ele = () => {
  const [userCount, setUserCount] = React.useState("200,000")

  React.useEffect(() => {
    const tenantId = "UG"
    const headers = {
      appId: "ultima",
      production: true,
      tenantId: tenantId,
    }

    fetch("https://bf.xn-io.com/leg/api/v1/guest/number-of-clients", {
      headers,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Failed to fetch")
        }
        return response.json()
      })
      .then(data => {
        setUserCount(data["numberOfClients"])
      })
      .catch(error => {
        throw new Error(`${error}`)
      })
  }, [])

  return (
    <Box>
      <section>
        <Overline invert>Join XENO</Overline>
        <Title invert>
          <h1>
            Invest your savings. Build your <span>future.</span>
          </h1>
        </Title>
        <Text>
          <p>
            Join over <strong>{userCount.toLocaleString()} </strong>
            people who trust XENO to plan, save, and invest for their financial
            goals.
          </p>
        </Text>
        <Actions>
          <GetStartedBtn
            title="Start Investing"
            section="splash"
            id="start_investing_button"
          />
        </Actions>
        <Partners>
          <span className="tag">Available on</span>
          <div className="partnerLogo">
            <Link to="/xeno-on-mtn-momo">
              <img src={MtnMomoLogo} alt="MTN MoMo Logo" />
            </Link>
          </div>
          <div className="partnerLogo">
            <img src={AirtelMoneyLogo} alt="Airtel Money Logo" />
          </div>
          {/* <div className="partnerLogo">
            <img src={StanbicLogo} alt="Stanbic Bank Logo" />
          </div> */}
        </Partners>
      </section>
    </Box>
  )
}
export default Ele
